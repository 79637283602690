<template>
  <div class="authorization">
    <div>
      <div class="logo">
        <img src="./img/logo.svg" />
      </div>
      <div class="authorization-title">
        <h2>
          <!-- 链接 Open ID Connect 账户 -->
          {{ $t('c2204') }}
        </h2>
      </div>
      <div class="authorization-content">
        <el-card class="box-card">
          <div>
            <span>
              <!-- 是否将Open ID Connect 账户 -->
              {{ $t('c2207') }}
            </span> <br/>
            <span style="margin:10px;display: block;">{{ account }} 
              <!-- 链接到用户 -->
              {{ $t('c2208') }}
            </span>
            {{ username }}？(<a class="link" @click="tologin">
              <!-- 不是您? -->
              {{ $t('c2213') }}
            </a>)
          </div>
          <div class="button-group">
            <el-button @click="allow('false')">
              <!-- 拒绝 -->
              {{ $t('label.refuse') }}
            </el-button>
            <el-button type="primary" @click="allow('true')">
              <!-- 允许 -->
              {{ $t('c2212') }}
            </el-button>
          </div>
          <span>
            <!-- 要随时撤销访问，请转到个人设置中的"链接" -->
            {{ $t('c2214') }}
          </span>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
// 授权页面
import * as Api from "../api";
export default {
  name: "authorization",
  data() {
    return {
      username: "aaaa@cloudcc.com",
    };
  },
  mounted() {
    // 隐藏加载框
    document.getElementById("lightning-loading").style.display = "none";
    // this.init()
    this.username = this.$route.query.username;
    this.account = this.$route.query.account;
    

  },
  methods: {
    /**
     * 对象转formdata格式 方法
     * @param  obj 对象数据
     * @returns  转为formdata格式的数据
     */
    jsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    //  授权
    allow(isAllow) {
      let params = {
        // state: this.$route.query.state,
        // accessToken: this.$route.query.token,
        // user_oauth_approval: isAllow,
        // orgId: this.$route.query.orgId || "",



        // username:"",
        // password:"",
        state:this.$route.query.state,
        accessToken:this.$route.query.token,
        user_oauth_approval: isAllow,



      };

      const config = {
                headers:{
                    accessToken: this.$CCDK.CCToken.getToken(),
                }
            };
      let orgid=`orgId=${this.$store.state.userInfoObj.orgId}`
      Api
        .linkAuthorize(this.jsToFormData(params),orgid,config)
        .then((res) => {
          if (res.data.data.state) {
            this.$message.warning(res.data.returnInfo);
          } else {
            if(res.data.data.lightningWebUrl){
               // 授权成功  跳主页
               window.location.href =res.data.data.lightningWebUrl + "/#/?binding=" +res.data.data.binding
            }else{
              // MFA验证
              if (res.data.result) {
                this.loginFlag = false;
                // todo status 状态。值为changePassword时意为修改密码；值为registerSuccess时意为注册成功；值为register时意为注册；值为mfavalidate意为MFA输入验证码；值为mfabound意为去绑定MFA
                // status状态。值为changePassword时意为修改密码；值为autoRegister时意为注册用户首次登录修改密码；值为registerSuccess时意为注册成功；值为register时意为注册；值为mfavalidate意为MFA输入验证码；值为mfabound意为去绑定MFA
                if (res.data.data.status) {
                  // 有status时 分几种状态
                  this.loginStatus = res.data.data.status;
                  if (
                    res.data.data.status == "changePassword" ||
                    res.data.data.status == "register" ||
                    res.data.data.status == "registerSuccess" ||
                    res.data.data.status == "autoRegister"
                  ) {
                    // 修改密码 注册
                    this.$router.replace({
                      path: "/changePassword",
                      query: {
                        username: this.form.userName,
                        accessToken: res.data.data.accessToken,
                        binding: res.data.data.binding,
                        modifyPwType: res.data.data.status,
                      },
                    });
                  } else if (res.data.data.status == "mfabound") {
                    // 去绑定MFA
                    this.$refs.enableMFA.open(res);
                  } else {
                    // mfavalidate 为MFA输入验证码
                    this.$refs.validationMFA.open(res);
                  }
                } else {
                  // 没有status时
                  this.accessToken = res.data.data.accessToken;
                  this.href = "./#/?binding=" + res.data.data.accessToken;
                  window.location.replace(this.href);
                }
              } else {
                this.loginFlag = false;
                this.returnInfo = res.data.returnInfo;
                // 因为验证需要接口数据 再验证一次
                this.submitForm();
              }
            }
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    tologin() {
      this.$router.push({
        // path: "/thirdLogin",
        path: "/singleLogoutLogin",
        query: {
          state: this.$route.query.state,
          orgId: this.$route.query.orgId || ""
        },
      });
    },
    init() {
      let url = window.location.href;
      // 截取问号后面的部分  state=111&auto=true
      let searchUrl = url.split("?")[1];
      // 将url解析成对象的形式 {state: '111', auto: 'true'}
      let paramsObj = this.$qs.parse(searchUrl);
      // 获取token  公司业务token
      let token = this.$CCDK.CCToken.getToken();
      // if (!token) {
      //   // 没有token直接跳转到登录
      //   this.$router.push({
      //     path: "singleLogoutLogin",
      //     // path: "/thirdLogin",
      //     query: {
      //       state: paramsObj.state,
      //       orgId: paramsObj.orgId || ""
      //     },
      //   });
      // } else {
        // 有token的情况
        // auto为true表示自动登录 此时需要请求接口做一下操作
        // 根据公司业务token去请求接口 判断此用户有没有授权 以及 获取现有用户的第三方token、state等
        if (paramsObj.auto == "true") {
          //当前页就是授权页

        } else {
          // auto为false表示不自动登录 直接跳转已有用户页面
          this.$router.push({
            path: "/singleLogoutLogged",
            // path: "/thirdLogged",
            query: {
              state: paramsObj.state,
              token: token,
              orgId: paramsObj.orgId || ""
            },
          });
        }
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.authorization {
  width: 100%;
  height: 100vh;
  background: rgba(245, 246, 249, 0.48);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul,
  li {
    list-style-type: disc;
  }
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 300px;
    }
  }
  .authorization-title {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .authorization-content {
    width: 100%;
    display: flex;
    justify-content: center;
    .box-card {
      width: 400px;
      ul {
        margin: 14px 0;
        padding: 0 0 0 40px;
      }
      .link {
        color: #409eff;
        cursor: pointer;
      }
      .button-group {
        margin: 15px 0;
        display: flex;
        justify-content: center;
        ::v-deep.el-button {
          padding: 12px 50px !important;
        }
      }
    }
  }
}
</style>
